<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Trasladar Registros</v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        >+ Nuevo</CButton
      >

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <!--div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div-->
        <v-data-table
          v-model="params.selected"
          :headers="fields"
          :items="items"
          item-key="nombre_real"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, 200, 500, -1]
          }"
          :items-per-page="50"
          :loading='loading'
          loading-text="Cargando..."
          show-select
        >

          
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="verImagen(item)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
        
      </v-card-text>
    </v-card>
    <br><br>
    <v-card elevation="2" tile v-if="params.selected.length">
      <v-card-title>Trasladar {{params.selected.length}} archivos seleccionados</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
           <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingArchivosLayout"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingArchivosLayout"
                v-model="params.archivoLayoutId"
                :items="archivosLayout"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Archivo del Layout Registro Fotográfico"
                :rules="required"
              ></v-select>
            </v-col>
           </v-row>  
          
        </v-container>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="trasladar()"
          :disabled="!valid"
          >Trasladar Archivos</CButton
        >
      </v-card-actions>
    </v-card>
    <br><br>
    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        width="98%"
        height="90"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="#BBD000"
              dark
            >{{dialogTitle}}</v-toolbar>
            <v-card-text class="cardImgs">
              
              <span>
                <v-card elevation="4" v-if="imagenZoom != ''">
                  <v-card-text>
                    <v-img
                      v-if="imagenZoom != ''"
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="100%"
                      max-width="100%"
                      :src=imagenZoom
                      @click="zoom('')"
                    ></v-img>
                  </v-card-text>
                </v-card>
                <v-card elevation="4" class="cardImg" v-if="imagenZoom == ''">
                  <v-card-text>
                    <v-img 
                      lazy-src="https://picsum.photos/id/11/10/6"
                      max-height="153"
                      max-width="263"
                      :src=getURLImagen(imagen.nombre_real)
                      @click="zoom(getURLImagen(imagen.nombre_real))"
                    ></v-img>
                    {{imagen.archivo_nombre}}
                  </v-card-text>
                </v-card>
              </span>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="cerrarImagenes()"
              >Volver</v-btn>
            </v-card-actions>
          </v-card>
        </template>
    </v-dialog>
  </div>
</template>

<script>
import registroFotograficoService from "@/services/registroFotografico.service";
import ArchivosService from "@/services/archivo.service";
import Filters from "@/components/filters";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";
import api from '@/services/api';

export default {
  name: "Layout",
  data() {
    return {
      items: [],
      archivos: [],
      registrosFotograficos: [],
      archivosLayout: [],
      fields: [
        { text: "Código de Carga Registro Fotográfico", value: "registroId" },
        { text: "Nombre", value: "archivo_nombre" },
        { text: "Fecha de Carga", value: "created_at" },
        { text: "Zip de Origen", value: "nombre_zip" },
        { text: "Ver imagen", align: 'center', value: 'actions' },
      ],
      search: null,
      filterFields: [
        { value: "nombre", label: "Placa" },
        {
          value: "registro_id",
          label: "Código de Carga Registro Fotográfico",
        },
      ],
      params: {
        archivoLayoutId: undefined, 
        selected: []
      },
      valid: false,
      required: [(value) => !!value || "Requerido"],
      dialog: false,
      dialogTitle: "",
      imagen: "",
      baseURL: '',
      imagenZoom: "",
      activePage: 1,
      loading: false,
      loadingArchivosLayout: false,
      loadingSave: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Repositorio Fotográfico - Trasladar"))
      this.$router.push("/dashboard");

    this.baseURL = api.defaults.baseURL
    if( this.baseURL.charAt(this.baseURL.length - 1) != '/'){
      this.baseURL = this.baseURL + '/'
    }

    this.fetchRegistrosFotograficos("");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      
      this.fetchRegistrosFotograficos(cadenaBusqueda);
    });

    this.fetchArchivosLayout("");
  },
  methods: {
    fetchRegistrosFotograficos(cadenaBusqueda) {
      this.loading = true;
      registroFotograficoService.getItems().then(
        (response) => {
          this.registrosFotograficos = response.data;
          /*this.registrosFotograficos = this.registrosFotograficos.map(function f(x) {
            return { ...x, archivo_nombre: x.archivo_layout.nombre };
          });*/
          //console.log("this.registrosFotograficos")
          //console.log(this.registrosFotograficos);

          //Se transfiere la cadena de búqueda
          this.fetchItems(cadenaBusqueda)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchItems(cadenaBusqueda) {
      this.loading = true
        
      registroFotograficoService.getNombresFotosPendientes(cadenaBusqueda).then(
        (response) => {
         
          //console.log("Resultado consulta:")
          //console.log(response.data)
          this.archivos = response.data

          let _this = this
          this.items = this.archivos.map(function f(x) {
            let registroId = x.split('_')[0]
            let archivo_nombre = x.substr(registroId.length + 1, x.length - registroId.length + 1)
            let elemento = _this.registrosFotograficos.find(x => x.id == registroId)
            let nombre_zip = 'No se encontro un Código de Carga relacionado.'
            let created_at = 'No se encontro un Código de Carga relacionado.'
            if (elemento != undefined) {
              nombre_zip = elemento.nombre_zip
              created_at = elemento.created_at
            } 
            return {
              nombre_real: x,
              registroId: registroId, 
              archivo_nombre: archivo_nombre, 
              nombre_zip: nombre_zip, 
              created_at: created_at
            };
          });

          //console.log("Resultado procesado:")
          //console.log(this.items)

          this.loading = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
        }
      )
    },
    fetchArchivosLayout(cadenaBusqueda) {
      this.loadingArchivosLayout = true;
      ArchivosService.getArchivosRepositorioFotografico().then(
        (response) => {
          this.archivosLayout = response.data;
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          //console.log(response.data);
          this.loadingArchivosLayout = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivosLayout = false;
        }
      );
    },
    trasladar() {
      this.loadingSave = true;

      registroFotograficoService.trasladar( this.params ).then(
      (response) => {
          alert("Archivos trasladados.")
          this.$router.push({ path: `/repositorio-fotografico/cargas` });
          console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );  
    },
    verImagen(item){
      // console.log(item)
      this.dialogTitle = `Código de Carga Registro Fotográfico: ${item.registroId}`   
      this.imagen = item
      this.dialog = true
    },
    getURLImagen(imagen){
      console.log(`${this.baseURL}api/registros_fotograficos/foto/${imagen}`)
      return `${this.baseURL}api/registros_fotograficos/foto_pendientes/${imagen}`
    },
    cerrarImagenes(){
      this.dialog = false
    },
    zoom(imagen) {
      this.imagenZoom = imagen
    },
  },
};
</script>

<style>

.v-dialog__content {
    z-index: 1030 !important;
}
.panoramico .v-input__control {
  height: 30px;
}

.tabla{
  width: 100%;
  border-width: 0.5px;
}
.tabla th{
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.tabla, .tabla tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e1e1e1;
}
.tabla td {
  padding-left: 16px;
  padding-right: 16px;
}

.cardImgs {
  overflow:auto;
}

.cardImg {
  margin: 1%;
  width: 28%;
  float: left;
}
</style>
